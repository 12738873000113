import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OAuthSnackbars } from '../types/o-auth-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class OAuthSnackbarsService {
  private snackbar = inject(MatSnackBar);
  private translate = inject(TranslateService);

  public successRegister(): void {
    this.snackbar.open(
      OAuthSnackbars.successRegister.message,
      OAuthSnackbars.successRegister.closeBtn,
      OAuthSnackbars.successRegister.config
    );
  }

  public failureRegisterEmailExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterEmailExists.message,
      OAuthSnackbars.failureRegisterEmailExists.closeBtn,
      OAuthSnackbars.failureRegisterEmailExists.config
    );
  }

  public failureRegisterPhoneExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterPhoneExists.message,
      OAuthSnackbars.failureRegisterPhoneExists.closeBtn,
      OAuthSnackbars.failureRegisterPhoneExists.config
    );
  }

  public failureRegisterNIFExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterNIFExists.message,
      OAuthSnackbars.failureRegisterNIFExists.closeBtn,
      OAuthSnackbars.failureRegisterNIFExists.config
    );
  }

  public failureRegisterExists(): void {
    this.snackbar.open(
      OAuthSnackbars.failureRegisterExists.message,
      OAuthSnackbars.failureRegisterExists.closeBtn,
      OAuthSnackbars.failureRegisterExists.config
    );
  }

  public failureRegisterBudgetFinished(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureRegisterBudgetFinished.message
      ),
      OAuthSnackbars.failureRegisterBudgetFinished.closeBtn,
      OAuthSnackbars.failureRegisterBudgetFinished.config
    );
  }

  public successValidate(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successValidate.message),
      OAuthSnackbars.successValidate.closeBtn,
      OAuthSnackbars.successValidate.config
    );
  }

  public failureForgotRole(): void {
    this.snackbar.open(
      OAuthSnackbars.failureForgotRole.message,
      OAuthSnackbars.failureForgotRole.closeBtn,
      OAuthSnackbars.failureForgotRole.config
    );
  }

  public failureValidate(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureValidate.message),
      OAuthSnackbars.failureValidate.closeBtn,
      OAuthSnackbars.failureValidate.config
    );
  }

  public failureLogin(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLogin.message),
      OAuthSnackbars.failureLogin.closeBtn,
      OAuthSnackbars.failureLogin.config
    );
  }

  public failureLoginCancelled(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginCancelled.message),
      OAuthSnackbars.failureLoginCancelled.closeBtn,
      OAuthSnackbars.failureLoginCancelled.config
    );
  }

  public failureLoginInactive(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginInactive.message),
      OAuthSnackbars.failureLoginInactive.closeBtn,
      OAuthSnackbars.failureLoginInactive.config
    );
  }

  public successForgot(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successForgot.message),
      OAuthSnackbars.successForgot.closeBtn,
      OAuthSnackbars.successForgot.config
    );
  }

  public failureForgotYouAreNotInTheCorrectApp(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureForgotYouAreNotInTheCorrectApp.message
      ),
      OAuthSnackbars.failureForgotYouAreNotInTheCorrectApp.closeBtn,
      OAuthSnackbars.failureForgotYouAreNotInTheCorrectApp.config
    );
  }

  public failureAccountCancelled(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureAccountCancelled.message),
      OAuthSnackbars.failureAccountCancelled.closeBtn,
      OAuthSnackbars.failureAccountCancelled.config
    );
  }

  public failureAccountInactive(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureAccountInactive.message),
      OAuthSnackbars.failureAccountInactive.closeBtn,
      OAuthSnackbars.failureAccountInactive.config
    );
  }

  public failureAccountCreated(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureAccountCreated.message),
      OAuthSnackbars.failureAccountCreated.closeBtn,
      OAuthSnackbars.failureAccountCreated.config
    );
  }

  public failureSentNewConfirmationCodeCancelled(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureSentNewConfirmationCodeCancelled.message
      ),
      OAuthSnackbars.failureSentNewConfirmationCodeCancelled.closeBtn,
      OAuthSnackbars.failureSentNewConfirmationCodeCancelled.config
    );
  }

  public failureSentNewConfirmationCodeInactive(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureSentNewConfirmationCodeInactive.message
      ),
      OAuthSnackbars.failureSentNewConfirmationCodeInactive.closeBtn,
      OAuthSnackbars.failureSentNewConfirmationCodeInactive.config
    );
  }

  public failureSentNewConfirmationCode(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureSentNewConfirmationCode.message
      ),
      OAuthSnackbars.failureSentNewConfirmationCode.closeBtn,
      OAuthSnackbars.failureSentNewConfirmationCode.config
    );
  }

  public successResetPassword(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successResetPassword.message),
      OAuthSnackbars.successResetPassword.closeBtn,
      OAuthSnackbars.successResetPassword.config
    );
  }

  public failureChangePasswordOldPassword(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureChangePasswordOldPassword.message
      ),
      OAuthSnackbars.failureChangePasswordOldPassword.closeBtn,
      OAuthSnackbars.failureChangePasswordOldPassword.config
    );
  }

  public failureChangePasswordSame(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePasswordSame.message),
      OAuthSnackbars.failureChangePasswordSame.closeBtn,
      OAuthSnackbars.failureChangePasswordSame.config
    );
  }

  public failureChangePassword(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePassword.message),
      OAuthSnackbars.failureChangePassword.closeBtn,
      OAuthSnackbars.failureChangePassword.config
    );
  }

  public failureChangePhone(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePhone.message),
      OAuthSnackbars.failureChangePhone.closeBtn,
      OAuthSnackbars.failureChangePhone.config
    );
  }

  public failureUserStatusIsNotCreated(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureUserStatusIsNotCreated.message
      ),
      OAuthSnackbars.failureUserStatusIsNotCreated.closeBtn,
      OAuthSnackbars.failureUserStatusIsNotCreated.config
    );
  }

  public failureChangePhoneGeneralMessage(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureChangePhoneGeneralMessage.message
      ),
      OAuthSnackbars.failureChangePhoneGeneralMessage.closeBtn,
      OAuthSnackbars.failureChangePhoneGeneralMessage.config
    );
  }

  public successSentNewConfirmationCode(): void {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.successSentNewConfirmationCode.message
      ),
      OAuthSnackbars.successSentNewConfirmationCode.closeBtn,
      OAuthSnackbars.successSentNewConfirmationCode.config
    );
  }

  public failureCreateAccount(): void {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureCreateAccount.message),
      OAuthSnackbars.failureCreateAccount.closeBtn,
      OAuthSnackbars.failureCreateAccount.config
    );
  }
}
