import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiQBonoEnv } from '@qaroni-core/config/apis/api-qbono/api-qbono.config';
import {
  OAuthObservableResponse,
  OAuthResponse,
  UserObservableResponse,
  UserResponse,
} from '../types/auth-response';
import {
  ChangePasswordJson,
  ForgotJson,
  LoginJson,
  OtpJson,
  OtpUsernameJson,
  ResetPasswordJson,
  SignUpJson,
  UserDataRegisterJson,
} from '../types/authentication';
import { UserRoleEnum } from '../types/user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class OAuthHttpService {
  private http = inject(HttpClient);

  public register$(signUpJSON: SignUpJson): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/clients/registers`;

    return this.http.post<UserResponse>(url, signUpJSON, {
      observe: 'response',
    });
  }

  public login$(loginJSON: LoginJson): OAuthObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/logins`;

    return this.http.post<OAuthResponse>(url, loginJSON, {
      observe: 'response',
    });
  }

  public validate$(
    userID: number | string,
    otpJSON: OtpJson
  ): OAuthObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/validates`;

    return this.http.patch<OAuthResponse>(url, otpJSON, {
      observe: 'response',
    });
  }

  public validateForgot$(
    userID: number | string,
    otpJSON: OtpJson
  ): OAuthObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/forgots/validates`;

    return this.http.patch<OAuthResponse>(url, otpJSON, {
      observe: 'response',
    });
  }

  public forgot$(forgotJSON: ForgotJson): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/forgots`;

    return this.http.post<UserResponse>(url, forgotJSON, {
      observe: 'response',
    });
  }

  public resetPassword$(
    userID: number | string,
    resetPasswordJSON: ResetPasswordJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/passwords/resets`;

    return this.http.post<UserResponse>(url, resetPasswordJSON, {
      observe: 'response',
    });
  }

  public otp$(userID: number | string): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/otp`;

    const roleJSON = {
      role: UserRoleEnum.CLIENT,
    };

    return this.http.post<UserResponse>(url, roleJSON, { observe: 'response' });
  }

  public otpUsername$(
    otpUsernameJSON: OtpUsernameJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/otp`;

    return this.http.post<UserResponse>(url, otpUsernameJSON, {
      observe: 'response',
    });
  }

  public changePassword$(
    userID: number | string,
    changePasswordJSON: ChangePasswordJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/passwords`;

    return this.http.patch<UserResponse>(url, changePasswordJSON, {
      observe: 'response',
    });
  }

  public getUserRegister$(userID: number | string): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/registers`;

    return this.http.get<UserResponse>(url, { observe: 'response' });
  }

  public updateUserRegister$(
    userID: number | string,
    updateUserRegisterJSON: UserDataRegisterJson
  ): UserObservableResponse {
    const url = `${ApiQBonoEnv.baseUrl}/users/${userID}/registers`;

    return this.http.patch<UserResponse>(url, updateUserRegisterJSON, {
      observe: 'response',
    });
  }
}
