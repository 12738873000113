import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { QaroniIntermediate } from '@qaroni-core/types/qaroni-intermediate/qaroni-intermediate';
import { Subject, shareReplay } from 'rxjs';
import { OAuthApiResponse, UserApiResponse } from '../types/auth-response';
import {
  ChangePasswordJson,
  ForgotJson,
  LoginJson,
  OtpJson,
  OtpUsernameJson,
  ResetPasswordJson,
  SignUpJson,
  UserDataRegisterJson,
} from '../types/authentication';
import { OAuth } from '../types/o-auth';
import { User } from '../types/user';
import { OAuthErrorsService } from './o-auth-errors.service';
import { OAuthHttpService } from './o-auth-http.service';
import { OAuthSnackbarsService } from './o-auth-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthService extends QaroniIntermediate {
  protected readonly oAuthSubject = new Subject<OAuth>();
  protected readonly userSubject = new Subject<User>();

  private oAuthHttp = inject(OAuthHttpService);
  private snackbars = inject(OAuthSnackbarsService);
  private oAuthErrors = inject(OAuthErrorsService);

  get getUserID(): number {
    return this.storage.getUserID;
  }

  get getClientID(): number {
    return this.storage.getClientID;
  }

  get getMerchantID(): number {
    return this.storage.getMerchantID;
  }

  // ==========================================================================================

  public getUser$() {
    return this.userSubject.asObservable().pipe(shareReplay(1));
  }

  public register(signUpJSON: SignUpJson): void {
    this.oAuthHttp
      .register$(signUpJSON)
      .subscribe({ next: this.nextRegister, error: this.errorRegister });
  }

  private nextRegister = (data: UserApiResponse): void => {
    if (this.status201(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successRegister();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorRegister = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorRegister(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public validate(userID: number | string, otpJSON: OtpJson): void {
    this.oAuthHttp
      .validate$(userID, otpJSON)
      .subscribe({ next: this.nextValidate, error: this.errorValidate });
  }

  private nextValidate = (data: OAuthApiResponse): void => {
    if (this.status200(data)) {
      const oAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
      this.snackbars.successValidate();
    } else {
      this.oAuthSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorValidate = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorValidate(error);
    this.oAuthSubject.next(null);
    this.errors.communication(error);
  };

  public validateForgot(userID: number | string, otpJSON: OtpJson): void {
    this.oAuthHttp.validateForgot$(userID, otpJSON).subscribe({
      next: this.nextValidateForgot,
      error: this.errorValidateForgot,
    });
  }

  private nextValidateForgot = (response: OAuthApiResponse): void => {
    if (this.status200(response)) {
      const oAuth = response.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
      this.snackbars.successValidate();
    } else {
      this.oAuthSubject.next(null);
      this.errors.apiInvalidResponse(response);
    }
  };

  private errorValidateForgot = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorValidate(error);
    this.oAuthSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public getOAuth$() {
    return this.oAuthSubject.asObservable().pipe(shareReplay(1));
  }

  public login(loginJSON: LoginJson): void {
    this.oAuthHttp
      .login$(loginJSON)
      .subscribe({ next: this.nextLogin, error: this.errorLogin });
  }

  private nextLogin = (data: OAuthApiResponse): void => {
    if (this.status200(data)) {
      const oAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorLogin = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorLogin(error, this.messageSubject);
    this.oAuthSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public forgot(forgotJSON: ForgotJson): void {
    this.oAuthHttp
      .forgot$(forgotJSON)
      .subscribe({ next: this.nextForgot, error: this.errorForgot });
  }

  private nextForgot = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successForgot();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorForgot = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorForgot(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public resetPassword(
    userID: number | string,
    resetPasswordJSON: ResetPasswordJson
  ): void {
    this.oAuthHttp.resetPassword$(userID, resetPasswordJSON).subscribe({
      next: this.nextResetPassword,
      error: this.errorResetPassword,
    });
  }

  private nextResetPassword = (data: UserApiResponse): void => {
    if (this.status201(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successResetPassword();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorResetPassword = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public otp(userID: number | string): void {
    this.oAuthHttp
      .otp$(userID)
      .subscribe({ next: this.nextOtp, error: this.errorOtp });
  }

  private nextOtp = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successSentNewConfirmationCode();
    } else {
      this.snackbars.failureSentNewConfirmationCode();
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorOtp = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorOtp(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public otpUsername(otpUsernameJSON: OtpUsernameJson): void {
    this.oAuthHttp
      .otpUsername$(otpUsernameJSON)
      .subscribe({ next: this.nextOtpUsername, error: this.errorOtpUsername });
  }

  private nextOtpUsername = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorOtpUsername = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public changePassword(changePasswordJSON: ChangePasswordJson): void {
    if (this.hasOAuth && this.storage.getUserID) {
      this.oAuthHttp
        .changePassword$(this.storage.getUserID, changePasswordJSON)
        .subscribe({
          next: this.nextChangePassword,
          error: this.errorChangePassword,
        });
    }
  }

  private nextChangePassword = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successResetPassword();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorChangePassword = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorChangePassword(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public getUserRegister(userID: number | string): void {
    this.oAuthHttp.getUserRegister$(userID).subscribe({
      next: this.nextGetUserRegister,
      error: this.errorGetUserRegister,
    });
  }

  private nextGetUserRegister = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorGetUserRegister = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public updateUserRegister(
    userID: number | string,
    updatePhoneJSON: UserDataRegisterJson
  ): void {
    this.oAuthHttp.updateUserRegister$(userID, updatePhoneJSON).subscribe({
      next: this.nextUpdateUserRegister,
      error: this.errorUpdateUserRegister,
    });
  }

  private nextUpdateUserRegister = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorUpdateUserRegister = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorUpdateUserRegister(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };
}
